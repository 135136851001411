import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAC-op_TpjXGRNy9zy80xTlYXsAqYeBreY',
		authDomain: 'cgl-harboe-test.firebaseapp.com',
		projectId: 'cgl-harboe-test',
		storageBucket: 'cgl-harboe-test.appspot.com',
		messagingSenderId: '746936432506',
		appId: '1:746936432506:web:5785729701feabc0b388c3'
	};
}

/* Connect to firebase project production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCdKHrGIr3Zcllqy1GRw_1mZKu-1Vsw80g',
		authDomain: 'cgl-harboe-production.firebaseapp.com',
		projectId: 'cgl-harboe-production',
		storageBucket: 'cgl-harboe-production.appspot.com',
		messagingSenderId: '1069520907915',
		appId: '1:1069520907915:web:2c9847241245cff4602e8e'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;