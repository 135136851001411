const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-welcome',
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i produktionen.`,
			en: `Welcome to second part of the hygiene training. This part will be about hygiene in the production areas.`,
			pl: `Witaj w drugiej części szkolenia z zasad higieny. Ta część dotyczy higieny w obszarze produkcji.`,
			de: `Willkommen zum zweiten Teil der Hygieneschulung. In diesem Teil geht es um die Hygiene in den Produktionsbereichen.`,
			ua: `Вітаємо на другому етапі тренування. Тепер ми розглянемо гігієну зон виробництва.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-remeber-stars',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre et modul. `,
			en: `Let's begin. Remember, you must have three stars to complete the module.`,
			pl: `Zaczynajmy. Pamiętaj, musisz uzyskać trzy gwiazdki, aby ukończyć moduł.`,
			de: `Fangen wir an. Denken Sie daran, Sie benötigen drei Sterne, um das Modul abzuschließen.`,
			ua: `Отже, почнімо. Пам'ятайте: щоб пройти модуль, необхідно заробити три зірки.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-clothing-redzone',
		layout: 'harboe-m2-clothing-redzone',
		subtype: 'images',
		text: {
			da: `Som du ved, er der forskel på røde og gule zoner. Hvis nu du skal ind i en rød zone, hvad skal du så altid have på?`,
			en: `As you know, we differentiate between yellow and red zones. If you are entering a red zone, what must you always wear?`,
			pl: `Jak wiesz, rozróżniamy żółte i czerwone strefy. Co musisz zawsze mieć na sobie, wchodząc do czerwonej strefy?`,
			de: `Wie Sie wissen, unterscheiden wir zwischen gelben und roten Zonen. Was müssen Sie immer tragen, wenn Sie eine rote Zone betreten?`,
			ua: `Як ви вже знаєте, жовті й червоні зони відрізняються. Заходячи в червону зону, що обов'язково носити?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // shoe wraps
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // clown shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // watch
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej nej. Det må du slet ikke have på i nogle zoner.`,
								en: `No no. You are not allowed to wear that in any zone.`,
								pl: `Nie. Nie wolno tego nosić w żadnej strefie.`,
								de: `Nein. Das dürfen Sie in keiner Zone tragen.`,
								ua: `Ні-ні. Цього не можна носити ні в якій із зон.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // hair net
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Det behøver du ikke i gule zoner.`,
								en: `Yes. But you don't need that in yellow zones.`,
								pl: `Tak. Ale nie musisz tego robić w żółtych strefach.`,
								de: `Ja. Aber Sie benötigen das nicht in gelben Zonen.`,
								ua: `Так. Але в жовтих зонах це не обов'язково.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // hearing protection
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det skal du have på, når skilte påbyder det.`,
								en: `You only have to wear that if a sign tells you to.`,
								pl: `Musisz nosić to tylko, jeśli nakazuje to znak.`,
								de: `Sie müssen das nur tragen, wenn ein Schild darauf hinweist.`,
								ua: `Це необхідно носити тільки, якщо так вказано на плакаті.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-guest-rules',
		shuffleOptions: false,
		text: {
			da: `På vej ind møder du en ekstern håndværker, der også skal ind. Men hvad er egentlig reglerne for gæster på Harboe?`,
			en: `On your way in,  you meet an external contractor who also has to enter. But what are the rules concerning guests at Harboe?`,
			pl: `Przy wejściu spotykasz zewnętrznego wykonawcę, który również musi wejść. Jakie obowiązują zasady dotyczące gości w Harboe?`,
			de: `Auf Ihrem Weg in den Bereich treffen Sie einen externen Mitarbeiter, der auch auf dem Weg in den Bereich ist. Wie lauten die Regeln bezüglich Gäste bei Harboe?`,
			ua: `Заходячи, ви зустрічаєте позаштатного підрядника, якому теж туди треба. Але які в Harboe правила щодо відвідувачів?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `De skal have en kontaktperson`,
					en: `They must have a contact`,
					pl: `Muszą mieć osobę kontaktową`,
					de: `Sie müssen einen Kontakt haben`,
					ua: `Має бути працівник підприємства, який нестиме відповідальність за відвідувача`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `De må kun færdes i afgrænsede områder`, 
					en: `They are only allowed in certain areas`,
					pl: `Mogą wchodzić tylko do określonych obszarów`,
					de: `Sie dürfen bestimmte Bereiche nicht betreten`,
					ua: `Доступ тільки до визначених зон`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Hvis de husker at lukke døren eller porten, så kan de bare gå ind`,
					en: `If they remember to close the door or gate, they are free to enter`,
					pl: `Mogą wejść, jeśli będą pamiętać o zamknięciu drzwi lub bramy`,
					de: `Wenn sie daran denken, die Tür oder das Tor zu schließen, können sie eintreten`,
					ua: `Вхід вільний, тільки потрібно зачиняти за собою двері чи ворота`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `De skal bære hårnet og kittel i rød zone`,
					en: `They must wear a hairnet and smock in red zones`,
					pl: `Muszą nosić w czerwonych strefach siatkę do włosów i fartuch`,
					de: `Sie müssen in den roten Zonen ein Haarnetz und einen Arbeitskittel tragen`,
					ua: `Обов'язково носити сіточку для волосся й робу в червоних зонах`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-meeting-guest-improperly-dressed',
		shuffleOptions: false,
		text: {
			da: `Hvad skal du gøre, hvis du møder en uden Harboe-tøj eller engangskittel?`,
			en: `What should you do if you meet someone without Harboe clothes or a single-use smock?`,
			pl: `Co należy zrobić, jeśli spotkasz kogoś bez stroju Harboe lub jednorazowego fartucha?`,
			de: `Was sollten Sie tun, wenn Sie jemanden ohne Harboe-Kleidung oder Einweg-Arbeitskittel sehen?`,
			ua: `Що робити, якщо ви зустрінете когось без спецодягу Harboe чи одноразової роби?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Bede dem gå tilbage til administrationen`,
					en: `Ask them to return to the administration`,
					pl: `Należy poprosić tę osobę, aby wróciła do administracji`,
					de: `Sie bitten, zur Verwaltung zurückzukehren`,
					ua: `Попросити повернутися до адміністрації`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Ringe til receptionen`, 
					en: `Call Transport & storage operations`,
					pl: `Należy zadzwonić do działu transportu i magazynowania`,
					de: `In der Abteilung für Transport & Lagerung anrufen`,
					ua: `Викликати представника відділу операцій з транспортування й зберігання`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Bede dem skifte til Harboe tøj`,
					en: `Ask them to change to Harboe clothes`,
					pl: `Należy poprosić tę osobę o założenie stroju Harboe`,
					de: `Bitten sie, Harboe-Kleidung anzuziehen`,
					ua: `Попросити перевдягнутися в спецодяг Harboe`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Gå til nærmeste leder`,
					en: `Inform my immediate manager`,
					pl: `Należy poinformować bezpośredniego przełożonego`,
					de: `Meinen unmittelbaren Manager informieren`,
					ua: `Повідомити безпосереднє керівництво`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Udlevere et hårnet`, 
					en: `Give them a hairnet`,
					pl: `Należy dać tej osobie siatkę do włosów`,
					de: `Ihnen ein Haarnetz geben`,
					ua: `Дати їм сіточку для волосся`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Bare gå videre`, 
					en: `Just move along`,
					pl: `Po prostu iść dalej`,
					de: `Einfach weitergehen`,
					ua: `Не звертати уваги`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-extra-attention',
		text: {
			da: `I produktionen er det vigtigt at være ekstra opmærksom på hygiejne. Se om du kan spotte problemerne på næste billede.`,
			en: `When in production areas, it's especially important to be aware of hygiene. See if you can spot the issues in the next image.`,
			pl: `W obszarze produkcji trzeba szczególnie dbać o higienę. Spróbuj znaleźć problemy na następnym obrazie.`,
			de: `In Produktionsbereichen ist es besonders wichtig, auf Hygiene zu achten. Versuchen Sie im nächsten Bild die Probleme zu finden.`,
			ua: `Перебуваючи в зонах виробництва, особливо важливо дотримуватися гігієни. Перевірте, чи є порушення на наступному зображенні.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'harboe-m2-spot-errors-production-hygiene-issues',
		layout: 'harboe-m2-production-hygiene-issues',
		background: 'production',
		backgroundModifier: 'machine-in-left-side',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`,
			pl: `Kliknij 4 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			de: `Klicken Sie auf die 4 Probleme. Klicken Sie auf OK wenn Sie fertig sind, oder nicht alle finden konnten.`,
			ua: `Спробуйте знайти 4 порушення. Коли знайдете всі або скільки зможете, натисніть «ОК».`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'cleaning-agent'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'open-gate'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mosquito-net'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'coffee-cup'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-open-gate',
		text: {
			da: `I sidste opgave var det en fejl, at porten stod åben. Hvorfor?`,
			en: `In the previous task, leaving the gate open was a mistake. Why is that?`,
			pl: `Zostawienie otwartej bramy w poprzednim zadaniu było błędem. Dlaczego?`,
			de: `In der vorherigen Aufgabe war es ein Fehler, die Türe offen zu lassen. Warum ist das so?`,
			ua: `У попередньому завданні помилкою було залишати ворота відчинені. Чому?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det gør, at skadedyr kan komme ind`,
					en: `It enables pests to enter`,
					pl: `W ten sposób do zakładu mogą przedostać się szkodniki`,
					de: `Es ermöglicht es Tieren, einzutreten`,
					ua: `Це дає шкідникам проникнути всередину`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det gør, at det trækker`, 
					en: `It creates a draft`,
					pl: `Powoduje to przeciąg`,
					de: `Dadurch entsteht ein Luftzug`,
					ua: `Це створює протяг`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det giver sollys, som kan skade produkterne`,
					en: `It allows sunlight to shine in which can damage products`,
					pl: `Przepuszcza światło słoneczne, które może uszkodzić produkty`,
					de: `Dadurch kann Sonnenlicht eindringen, was die Produkte beschädigen kann`,
					ua: `Це може спричинити пошкодження продуктів сонячним світлом`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet på lageret. Hvad skal du gøre?`,
			en: `You spot a mouse on the floor in the warehouse. What should you do?`,
			pl: `Widzisz mysz na podłodze magazynu. Co należy zrobić?`,
			de: `Sie sehen im Lager eine Maus auf dem Boden. Was sollten Sie tun?`,
			ua: `Ви помітили на складі мишу. Що вам необхідно зробити?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`,
					pl: `Mieć oko na mysz i wezwać pomoc`,
					de: `Versuchen, die Maus im Auge zu behalten, während ich Unterstützung anfordere`,
					ua: `Покликати на допомогу, не випускаючи мишу з поля зору`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Sørg for at din nærmeste leder eller kvalitet også får besked om hændelsen. Også selvom I fanger den.`,
								en: `Make sure your immediate manager or Quality is informed about the event - even if you catch it.`,
								pl: `Poinformować o tym bezpośredniego przełożonego lub specjalistę ds. jakości – nawet jeśli uda Ci sie złapać mysz.`,
								de: `Stellen Sie sicher, dass Ihr unmittelbarer Manager oder die Qualitätssicherung über das Ereignis informiert wird - selbst, wenn Sie die Maus fangen sollten.`,
								ua: `Повідомити про інцидент безпосереднє керівництво або відділ контролю якості — навіть, якщо мишу вдалося спіймати.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`, 
					en: `Try to capture the mouse myself and then call for help if I can't`,
					pl: `Spróbować złapać mysz i wezwać pomoc, jeśli nie zdołam tego zrobić`,
					de: `Ich versuche die Maus zu fangen und bitte um Hilfe, wenn ich das nicht schaffe`,
					ua: `Спробувати самостійно зловити мишу, а якщо не вдасться — покликати на допомогу`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my immediate manager at the end of the shift.`,
					pl: `Poinformować bezpośredniego przełożonego na koniec zmiany.`,
					de: `Ich teile es meinem unmittelbaren Manager am Ende meiner Schicht mit.`,
					ua: `Повідомити безпосереднє керівництво після завершення робочої зміни.`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-after-mouse',
		text: {
			da: `Efter I har fået musen ud, hvem skal du så ringe til?`,
			en: `After you've gotten rid of the mouse, who should you call?`,
			pl: `Po pozbyciu się myszy do kogo nalezy zadzwonić?`,
			de: `Wen sollten Sie anrufen, nachdem Sie die Maus losgeworden sind?`,
			ua: `До кого потрібно звернутися, коли ви вже зловили мишу?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Nærmeste leder`,
					en: `My immediate manager`,
					pl: `Mój bezpośredni przełożony`,
					de: `Meinen unmittelbaren Manager`,
					ua: `Безпосереднє керівництво`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Vedligehold`, 
					en: `Maintenance`,
					pl: `Dział konserwacji`,
					de: `Die Instandhaltung`,
					ua: `Техобслуговування`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, { 
				id: 3, 
				text: {
					da: `Ekspeditionen`,
					en: `Transport & storage operations`,
					pl: `Dział ds. transportu i magazynowania`,
					de: `Die Abteilung für Transport & Lagerung`,
					ua: `Приймальню`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Teknisk afdeling`, 
					en: `Technical department`,
					pl: `Dział techniczny`,
					de: `Die technische Abteilung`,
					ua: `Технічний відділ`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true, 
				text: {
					da: `Kvalitet`, 
					en: `Quality`,
					pl: `Dział kontroli jakości`,
					de: `Die Qualitätssicherung`,
					ua: `Відділ контролю якості`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-many-procedures',
		text: {
			da: `Der er i det hele taget mange procedurer og instruktioner. Det er alt sammen for at sikre høj hygiejnestandard og dermed fødevaresikkerheden.`,
			en: `All in all we have a lot of procedures and instructions. That is all to ensure high hygiene standards and thus high food safety.`,
			pl: `Mamy wiele procedur i instrukcji. Służą one zapewnianiu wysokich standardów higieny, a co za tym idzie – wysokiego poziomu bezpieczeństwa żywności.`,
			de: `Insgesamt haben wir viele Abläufe und Anweisungen. Das alles, um hohe Hygienestandards und Lebensmittelsicherheit zu gewährleisten.`,
			ua: `Загалом у нас достатньо багато процедур і вказівок. Їхня мета — забезпечити дотримання високих стандартів гігієни й, відповідно, харчової безпеки.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-water-plant',
		text: {
			da: `Hvad gælder, hvis du skal ind i vandværk eller tappemaskine?`,
			en: `What are the specific rules if you must enter a water treatment plant or a filling machine?`,
			pl: `Jakie obowiązują zasady, gdy musisz wejść do zakładu uzdatniania wody lub obszaru z napełniarką?`,
			de: `Was sind die spezifischen Anweisungen, wenn Sie eine Wasseraufbereitungsanlage oder Abfüllmaschine betreten?`,
			ua: `Які конкретні правила стосуються входу на територію обробного заводу чи розливної станції?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Jeg skal bruge skoovertræk`,
					en: `I must use a shoe cover`,
					pl: `Muszę użyć osłony na buty`,
					de: `Ich muss einen Schuhüberzieher tragen`,
					ua: `Обов'язково носити бахили`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja. Og det kan også være markeret enkelte andre steder.`,
								en: `Yes. And you might have to do this in a few other areas if signs tell you to.`,
								pl: `Tak. I może być konieczne zrobienie tego w kilku innych miejscach, jeśli tak nakazują znaki.`,
								de: `Ja. Und Sie werden das auch in anderen Bereichen tun müssen, wenn Schilder darauf hinweisen.`,
								ua: `Так. Це також треба робити й у деяких інших місцях, якщо так вказано на плакатах.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Jeg skal bruge særlige sko`, 
					en: `I need special shoes`,
					pl: `Potrzebuję specjalnych butów`,
					de: `Ich brauche besondere Schuhe`,
					ua: `Мені потрібне спеціальне взуття`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Jeg skal gøre honnør`,
					en: `I must salute`,
					pl: `Muszę zasalutować`,
					de: `Ich muss salutieren`,
					ua: `Обов'язково виконувати жест вітання`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, 
				text: {
					da: `Jeg skal vaske hænder`, 
					en: `I must wash my hands`,
					pl: `Muszę umyć ręce`,
					de: `Ich muss meine Hände waschen`,
					ua: `Обов'язково мити руки`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'harboe-m2-spot-errors-production',
		layout: 'harboe-m2-production',
		background: 'production',
		backgroundModifier: 'no-lamps',
		text: {
			da: `Find de 5 fejl på billedet. Klik OK, når du har dem, eller hvis du ikke kan finde dem alle`,
			en: `Spot the 5 issues in the image. Click OK when you are ready or if you can't find them all.`,
			pl: `Znajdź 5 problemów widocznych na obrazie. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			de: `Finden Sie die 5 Probleme im Bild. Klicken Sie auf OK wenn Sie fertig sind, oder nicht alle finden konnten.`,
			ua: `Спробуйте знайти 5 порушень на зображенні. Коли знайдете всі або скільки зможете, натисніть «ОК».`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'ear-protection',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig! Skiltet viser, at du SKAL bære høreværn. Det er for din egen sikkerheds skyld.`,
								en: `Exactly! The sign says you MUST wear ear protection. It's for you own safety. `,
								pl: `Dokładnie! Znak oznacza, że MUSISZ nosić ochraniacze na uszy. To dla Twojego bezpieczeństwa.`,
								de: `Genau! Auf dem Schild steht, dass man einen Gehörschutz tragen MUSS. Das ist zu Ihrer eigenen Sicherheit.`,
								ua: `Саме так! На плакаті вказано, що носити вушний захист ОБОВ'ЯЗКОВО. Це для вашої ж безпеки.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'broken-lamp'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'broom',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set. De skal selvfølgelig hænges på plads. `,
								en: `Nice catch. They should obviously be put in their right place. `,
								pl: `Dobrze. Te rzeczy trzeba oczywiście umieścić we właściwym miejscu.`,
								de: `Gut gesehen. Sie sollten am richtigen Ort abgelegt werden.`,
								ua: `Правильно. Це обов'язково треба класти на відповідне місце.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'paperclip',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt fanget. Clips må ikke medbringes i produktionen.`,
								en: `Yes! No paper clips are allowed in production.`,
								pl: `Tak! Spinacze nie są dozwolone w obszarze produkcji.`,
								de: `Ja! Büroklammern sind in der Produktion nicht erlaubt.`,
								ua: `Так! У зону виробництва заборонено приносити скріпки.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'blue-box'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-blocked-escape',
		text: {
			da: `På det foregående billede, hvorfor var den store kasse ved døren et problem?`,
			en: `In the previous image, why was the big box near the door a problem?`,
			pl: `W poprzednim obrazie dlaczego duża skrzynia obok drzwi stanowiła problem?`,
			de: `Warum war die große Kiste bei der Tür im vorherigen Bild ein Problem?`,
			ua: `На попередньому зображенні біля дверей була велика короба. Чому це вважається порушенням?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Den spærrede flugtevejen`,
					en: `It blocked an escape route`,
					pl: `Blokowała wyjście awaryjne`,
					de: `Sie hat den Fluchtweg versperrt`,
					ua: `Вона перекривала аварійний вихід`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Den var grim`, 
					en: `It was ugly`,
					pl: `Była brzydka`,
					de: `Sie war hässlich`,
					ua: `Вона була неестетична`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Den var i stykker`,
					en: `It was broken`,
					pl: `Była zepsuta`,
					de: `Sie war kaputt`,
					ua: `Вона була поламана`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Den var den forkerte farve`, 
					en: `It had the wrong colour`,
					pl: `Była niewłaściwego koloru`,
					de: `Sie hatte die falsche Farbe`,
					ua: `Вона була невідповідного кольору`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m2-instructions-spot-escape-route',
		text: {
			da: `Det er altid en god idé at spotte flugtvejene i et rum. Og se gerne på afdelingens beredskabsplan, hvor der hænger brandslukningsudstyr. `,
			en: `It's always good to spot the escape routes in a room. And do take a look at the plan in your department so you know where to find fire extinguishers.`,
			pl: `Zawsze dobrze jest zwrócić uwagę na wyjścia ewakuacyjne z pomieszczenia. Spójrz na plan swojego zakładu, aby wiedzieć, gdzie znajdują się gaśnice.`,
			de: `Es ist immer gut, die Fluchtwege eines Raumes zu kennen. Schauen Sie sich auch den Plan in Ihrer Abteilung an, damit Sie wissen, wo sich die Feuerlöscher befinden.`,
			ua: `Завжди варто знати, де в приміщенні аварійні виходи. Також радимо ознайомитися з плануванням свого відділу, щоб знати, де вогнегасники.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-lamp-and-paperclip',
		text: {
			da: `På den tidligere opgave var papirclip'en og den ødelagte lampe også problemer. Hvorfor?`,
			en: `In the previous task, the paper clip and the broken lamps were also problems. Why is that?`,
			pl: `W poprzednim zadaniu spinacz i zepsute lampy również stanowiły problemy. Dlaczego?`,
			de: `In der vorherigen Aufgabe waren auch die Büroklammer und die kaputte Lampe ein Problem. Warum ist das so?`,
			ua: `У попередньому завданні скріпка й розбиті лампочки були порушенням. Чому?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Små stykker plast eller metal kan forurene produkterne`,
					en: `Small pieces of plastic or metal can contaminate products`,
					pl: `Małe kawałki plastiku lub metalu mogą doprowadzić do skażenia produktów`,
					de: `Kleine Metall- oder Plastikteile können das Produkt kontaminieren`,
					ua: `Маленькими пластиковими чи металевими часточками можна забруднити продукцію`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Man kan falde over stumperne`, 
					en: `The pieces can make you trip`,
					pl: `Ktoś może przewrócić się przez nie`,
					de: `Die Teile können Sie zum stolpern bringen`,
					ua: `На уламках можна посковзнутися`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det ser ikke pænt ud`,
					en: `It doesn't look nice`,
					pl: `Źle wyglądają`,
					de: `Es sieht nicht schön aus`,
					ua: `Це створює неохайний вигляд`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det kan være brandfarligt`, 
					en: `It's a fire hazard`,
					pl: `Stanowią niebezpieczeństwo pożarowe`,
					de: `Es stellt eine Brandgefahr dar`,
					ua: `Це пожежонебезпечно`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-why-contamination',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can be contaminated in different ways. Why is contamination a problem?`,
			pl: `Nasze produkty mogą ulec skażeniu na różne sposoby. Dlaczego skażenie jest problemem?`,
			de: `Unsere Produkte können auf verschiedene Weise kontaminiert werden. Warum ist eine Kontaminierung ein Problem?`,
			ua: `Є різні шляхи забруднення продукції. У чому проблема забруднення?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`,
					pl: `Może być niebezpieczne`,
					de: `Sie kann gefährlich sein`,
					ua: `Це може бути небезпечно`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan ødelægge smagen`, 
					en: `It can ruin the taste`,
					pl: `Może zepsuć smak żywności`,
					de: `Sie kann den Geschmack verderben`,
					ua: `Це може зіпсувати смак`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`,
					pl: `Może doprowadzić do niezadowolenia klientów`,
					de: `Sie kann unzufriedene Kunden hervorrufen`,
					ua: `Це може спричинити невдоволення споживачів`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det udleder mere CO2`, 
					en: `It emits more CO2`,
					pl: `Prowadzi do większej emisji CO2`,
					de: `Sie stößt mehr CO2 aus`,
					ua: `Це збільшує викиди CO²`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Forurening er kun et problem, hvis Kvalitet ser det`, 
					en: `Contamination is only an issue if Quality spots it`,
					pl: `Skażenie jest problemem tylko, jeśli zauważy je specjalista ds. kontroli jakości`,
					de: `Kontamination ist nur ein Problem, wenn die Qualitätssicherung sie findet`,
					ua: `Проблема виникає тільки, якщо про це дізнається відділ контролю якості`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Det kan gøre vores tøj beskidt`, 
					en: `It can make our clothes dirty`,
					pl: `Może doprowadzić do zabrudzenia naszych ubrań`,
					de: `Sie kann die Kleidung verschmutzen`,
					ua: `Це може забруднити одяг`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-close-to-production',
		text: {
			da: `Du er tæt på produktionen hver dag, så du ser måske problemer, andre overser.`,
			en: `You are close to the production every day, so you might spot things others overlook.`,
			pl: `Każdego dnia masz bliski kontakt z naszymi produktami i zapewne zauważasz rzeczy, które inni pomijają.`,
			de: `Sie sind täglich in der Produktion und stoßen eventuell auf Dinge, die andere übersehen.`,
			ua: `Щоденно перебуваючи на виробництві, ви можете помітити те, чого не помітили інші.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m2-mc-inform-about-problems',
		text: {
			da: `Hvis du ser noget, som kan være et problem for hygiejnen, hvem skal du så informere?`,
			en: `If you see something that can be a hygiene issue, who should you inform?`,
			pl: `Jeśli dostrzeżesz coś, co może powodować problemy higieniczne, kogo należy o tym poinformować?`,
			de: `Wenn Sie etwas bemerken, dass ein Hygienerisiko darstellen könnte, wen sollten Sie darüber informieren?`,
			ua: `Якщо ви бачите щось, що може бути порушенням гігієни, кого слід повідомити?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Teknisk afdeling`,
					en: `Technical department`,
					pl: `Dział techniczny`,
					de: `Die technische Abteilung`,
					ua: `Технічний відділ`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Kvalitet`, 
					en: `Quality`,
					pl: `Dział kontroli jakości`,
					de: `Die Qualitätssicherung`,
					ua: `Відділ контролю якості`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Nærmeste leder`,
					en: `Immediate manager`,
					pl: `Bezpośredni przełożony`,
					de: `Den unmittelbaren Manager`,
					ua: `Безпосереднє керівництво`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Vedligehold`,
					en: `Maintenance`,
					pl: `Dział konserwacji`,
					de: `Die Instandhaltung`,
					ua: `Техобслуговування`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Receptionen`,
					en: `The Reception`,
					pl: `Recepcja`,
					de: `Die Rezeption`,
					ua: `Приймальню`
				},

			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Ekspeditionen`,
					en: `Transport & storage operations`,
					pl: `Dział ds. transportu i magazynowania`,
					de: `Die Abteilung für Transport & Lagerung`,
					ua: `Відділ операцій із транспортування й зберігання`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, 
				text: {
					da: `Laboratoriet`,
					en: `The laboratory`,
					pl: `Laboratorium`,
					de: `Das Labor`,
					ua: `Лабораторію`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-closer-look-at-zones',
		text: {
			da: `Inden vi slutter, skal vi lige kigge nærmere på det med gule og røde zoner. `,
			en: `Before finishing we'll take a closer look at yellow and red zones.`,
			pl: `Zanim skończymy, przyjrzymy się bliżej żółtym i czerwonym strefom.`,
			de: `Bevor wir zum Ende kommen, werfen wir noch einen genaueren Blick auf die gelben und roten Zonen.`,
			ua: `На сам кінець докладніше розглянемо жовті й червоні зони.`
		}
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, { 
		taskId: 'harboe-m2-order-zone-guidelines',
		text: {
			da: `Hvad gælder hvor? Placér følgende regler i de rigtige kasser, alt efter hvor de gælder.`,
			en: `What is true where? Sort the following rules into the right boxes, depending on where they apply.`,
			pl: `Co jest prawdą w poszczególnych miejscach? Posortuj następujące zasady, umieszczając je w odpowiednich polach, zależnie od tego, gdzie obowiązują.`,
			de: `Was trifft wo zu? Sortieren Sie die folgenden Regeln in die richtigen Boxen, je nachdem wo sie gelten.`,
			ua: `Що буде правильно в яких обставинах? Розсортуйте ці правила до відповідних категорій, залежно від того, де вони діють.`
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1,
				title: {
					da: `Gælder i rød og gul zone`,
					en: `Red and yellow zones`,
					pl: 'Czerwone i żółte strefy',
					de: 'Rote und gelbe Zonen',
					ua: `Червона і жовта зони`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2,
				title: {
					da: `Gælder i gul zone`,
					en: `Green and yellow zones`,
					pl: 'Zielone i żółte strefy',
					de: 'Grüne und gelbe Zonen',
					ua: `Зелена і жовта зони`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3,
				title: {
					da: `Gælder i alle zoner`,
					en: `All zones`,
					pl: 'Wszystkie strefy',
					de: 'Alle zonen',
					ua: `Усі зони`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 4,
				title: {
					da: `Gælder overalt inkl. administration`,
					en: `Applies everywhere incl. administration`,
					pl: 'Wszystkie miejsca, włącznie z działem administracji',
					de: 'Überall, inklusive der Verwaltung',
					ua: `Скрізь в т.ч. адміністрація`
				}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Jeg må kun bruge udleverede sakse og knive`, 
					en: `I'm only allowed to use knives and scissors supplied by Harboe`,
					pl: 'Wolno mi używać tylko noży i nożyczek dostarczonych przez firmę Harboe',
					de: 'Ich darf nur Messer und Scheren verwenden, die von Harboe bereitgestellt werden',
					ua: `Дозволено користуватися тільки ножами й ножицями від Harboe`
				}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Mad må ikke medtages`, 
					en: `No food allowed`,
					pl: 'Zakaz wnoszenia jedzenia',
					de: 'Nahrungsmittel sind nicht erlaubt',
					ua: `Заборонено приносити їжу`
				}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Jeg må drikke vand og varme drikke i udleverede termokrus`, 
					en: `I'm allowed to drink water and hot beverages in supplied cups`,
					pl: 'Mogę pić wodę i napoje gorące w dostarczonych kubkach',
					de: 'Ich darf Wasser und Heißgetränke in bereitgestellten Bechern trinken',
					ua: `Дозволено пити воду й гарячі напої з наданих чашок`
				}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Jeg må ikke indtage alkohol eller stoffer`, 
					en: `I'm not allowed to drink alcohol or do drugs`,
					pl: 'Nie wolno mi pić alkoholu ani zażywać narkotyków',
					de: 'Ich darf keinen Alkohol trinken oder Drogen nehmen',
					ua: `Заборонено вживати алкоголь чи наркотики`
				}, categoryIds: [4]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, 
				text: {
					da: `Jeg må ikke bruge kraftigt duftende parfume`, 
					en: `I'm not allowed to use heavy smelling perfume`,
					pl: 'Nie wolno mi używać perfumów o intensywnym zapachu',
					de: 'Ich darf kein stark riechendes Parfüm tragen',
					ua: `Заборонено використовувати парфуми із сильним запахом`
				}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, 
				text: {
					da: `Jeg må ikke have kunstige øjenvipper`, 
					en: `I'm not allowed to wear artificial eyelashes`,
					pl: 'Nie wolno mi nosić sztucznych rzęs',
					de: 'Ich darf keine künstlichen Wimpern tragen',
					ua: `Заборонено носити штучні вії`
				}, categoryIds: [1],
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, 
				text: {
					da: `Jeg må ikke ryge `, 
					en: `I'm not allowed to smoke`,
					pl: 'Nie wolno mi palić',
					de: 'Ich darf nicht rauchen',
					ua: `Заборонено палити`
				}, categoryIds: [4]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 8, 
				text: {
					da: `Jeg må ikke spise produkter med nødder`, 
					en: `I'm not allowed to ea products containing nuts`,
					pl: 'Nie wolno mi spożywać produktów zawierających orzechy',
					de: 'Ich darf keine Produkte mit Nüssen essen',
					ua: `Мені заборонено їсти продукти, що містять горіхи`
				}, categoryIds: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på Harboe. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at Harboe. There are no right and wrong answers. You don't get points for answering, and you answer anonymously.`,
			pl: `Na koniec mamy cztery pytania na temat kultury bezpieczeństwa żywnościowego w firmie Harboe. Nie ma prawidłowych ani złych odpowiedzi. Nie otrzymujesz punktów za te pytania, a Twoje odpowiedzi są anonimowe.`,
			de: `Zum Schluss noch vier Fragen zur Kultur der Lebensmittelsicherheit bei Harboe. Es gibt keine richtigen oder falschen Antworten. Sie erhalten keine Punkte für das Beantworten der Fragen und Sie beantworten die Fragen anonym.`,
			ua: `Нарешті, кілька запитань щодо культури харчової безпеки в Harboe. Тут не буде слушних чи хибних відповідей, вам не буде нараховано бали, а відповіді будуть анонімними.`
		},
		skipTaskConditions: [
			{type: 'not-first-module-session'}
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 'harboe-m2-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`,
			pl: `Autorytet i odpowiedzialność`,
			de: `Autorität und Verantwortung`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `To what extend do you agree with the following statement?
				<br />I'm authorised and obliged to act if I see something that can be a food safety risk.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem?
				<br />Mam prawo i obowiązek do działania, jeśli widzę coś, co może stanowić ryzyko dla bezpieczeństwa żywności.`,
			de: `Inwieweit stimmen Sie der folgenden Aussage zu?
				<br />Ich bin befugt und verpflichtet, zu handeln, wenn ich etwas sehe, das ein Risiko für die Lebensmittelsicherheit darstellen kann.`,
			ua: `Наскільки ви погоджуєтеся з наступним твердженням?
				<br />У мене є право й обов'язок вживати заходів у разі виявлення можливого порушення харчової безпеки.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					de: `Stimme nachdrücklich zu`,
					ua: `Сильно погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					de: `Stimme zu`,
					ua: `Погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					de: `Weder noch`,
					ua: `І не погоджуюся, і не заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					de: `Stimme nicht zu`,
					ua: `Заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					de: `Stimme nachdrücklich nicht zu`,
					ua: `Сильно заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					de: `Ich weiß es nicht`,
					ua: `Не знаю`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 'harboe-m2-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `Responds if others do not follow the rules`,
			pl: `Reaguje, jeśli inni nie przestrzegają zasad`,
			de: `Reagiert, wenn andere sich nicht an die Regeln halten`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `To what extend do you agree with the following statement? 
				<br />I act if I see others who do not adhere to hygiene guidelines.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem?
				<br />Reaguję, gdy zauważę kogoś, kto nie przestrzega wytycznych dotyczących higieny.`,
			de: `Inwieweit stimmen Sie der folgenden Aussage zu? 
				<br />Ich handle, wenn ich sehe, dass sich andere nicht an die Hygienerichtlinien halten.`,
			ua: `Наскільки ви погоджуєтеся з наступним твердженням?
				<br />Я вживаю заходів, виявивши, що хтось інший не дотримується правил гігієни.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					de: `Stimme nachdrücklich zu`,
					ua: `Сильно погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					de: `Stimme zu`,
					ua: `Погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					de: `Weder noch`,
					ua: `І не погоджуюся, і не заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					de: `Stimme nicht zu`,
					ua: `Заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					de: `Stimme nachdrücklich nicht zu`,
					ua: `Сильно заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					de: `Ich weiß es nicht`,
					ua: `Не знаю`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 'harboe-m2-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solve/report safetyissues`,
			pl: `Rozwiązuj/zgłaszaj problemy związane z bezpieczeństwem`,
			de: `Sicherheitsprobleme lösen/melden`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det og informerer min nærmeste leder?`,
			en: `To what extend do you agree with the following statement?
				<br />If I see a food safety issue, I solve it and inform my immediate manager.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem?
				<br />Jeśli widzę problem dotyczący bezpieczeństwa żywnościowego, rozwiązuję go i informuję bezpośredniego przełożonego.`,
			de: `Inwieweit stimmen Sie der folgenden Aussage zu? 
				<br />Wenn ich ein Problem mit der Lebensmittelsicherheit feststelle, löse ich es und informiere meinen unmittelbaren Manager darüber.`,
			ua: `Наскільки ви погоджуєтеся з наступним твердженням?
				<br />У разі виявлення порушення харчової безпеки, я усуваю його й повідомляю про інцидент безпосереднє керівництво.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					de: `Stimme nachdrücklich zu`,
					ua: `Сильно погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					de: `Stimme zu`,
					ua: `Погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					de: `Weder noch`,
					ua: `І не погоджуюся, і не заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`,
					pl: `Nie zgadzam się`,
					de: `Stimme nicht zu`,
					ua: `Заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					de: `Stimme nachdrücklich nicht zu`,
					ua: `Сильно заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					de: `Ich weiß es nicht`,
					ua: `Не знаю`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 'harboe-m2-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Something is being done about reports`,
			pl: `Coś się robi w sprawie raportów`,
			de: `Es wird etwas gegen Meldungen unternommen`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `To what extend do you agree with the following statement?
				<br />If I report a food safety issue, something will be done about it.`,
			pl: `W jakim stopniu zgadzasz się z następującym stwierdzeniem?
				<br />Jeśli zgłoszę problem dotyczący bezpieczeństwa żywnościowego, zostaną podjęte kroki w tej sprawie.`,
			de: `Inwieweit stimmen Sie der folgenden Aussage zu? 
				<br />Wenn ich ein Problem mit der Lebensmittelsicherheit melde, wird etwas dagegen unternommen.`,
			ua: `Наскільки ви погоджуєтеся з наступним твердженням?
				<br />Якщо я повідомляю про порушення харчової безпеки, щодо цього порушення буде вжито заходи.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`,
					pl: `Zdecydowanie się zgadzam`,
					de: `Stimme nachdrücklich zu`,
					ua: `Сильно погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`,
					pl: `Zgadzam się`,
					de: `Stimme zu`,
					ua: `Погоджуюся`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`,
					pl: `Nie mam zdania`,
					de: `Weder noch`,
					ua: `І не погоджуюся, і не заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disgree`,
					pl: `Nie zgadzam się`,
					de: `Stimme nicht zu`,
					ua: `Заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`,
					pl: `Zdecydowanie się nie zgadzam`,
					de: `Stimme nachdrücklich nicht zu`,
					ua: `Сильно заперечую`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`,
					pl: `Nie wiem`,
					de: `Ich weiß es nicht`,
					ua: `Не знаю`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m2-instructions-finish',
		text: {
			da: `Godt klaret! Du er nu igennem andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `That's it, you are through the second module. If you have three stars, you have completed this round of hygiene training. If not, you have to take this module once more.`,
			pl: `To koniec drugiego modułu. Jeśli masz trzy gwiazdki, ukończyłeś(-aś) to szkolenie z zasad higieny. Jeśli nie, musisz ukończyć ten moduł ponownie.`,
			de: `Das war's, Sie haben das zweite Modul abgeschlossen. Wenn Sie drei Sterne haben, haben Sie diese Runde der Hygieneschulung abgeschlossen. Wenn nicht, müssen Sie dieses Modul wiederholen.`,
			ua: `Це все — ви пройшли другий модуль. Якщо зібрано три зірки, цей етап тренування з гігієни завершено. В іншому разі доведеться пройти модуль заново.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};