const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Velkommen til første del af hygiejnetræningen. Jeg er din leder og hjælper dig gennem spillet. `,
			en: `Welcome to the first part of the hygiene training. I'm your manager, and I'll help you through the game.`,
			pl: `Witaj w pierwszej części szkolenia z zasad higieny. Jestem twoim kierownikiem i pomogę ci podczas gry.`,
			de: `Willkommen zum ersten Teil der Hygieneschulung. Ich bin Ihr Manager und ich werde Sie durch das Spiel begleiten.`,
			ua: `Вітаємо на першому етапі тренування з гігієни. Як ваш менеджер, я допомагатиму вам протягом при.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du. `,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za wykonywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			de: `In diesem Spiel erhalten Sie Punkte für das Lösen von Aufgaben. Je weniger Fehler Sie machen, desto mehr Punkte bekommen Sie.`,
			ua: `У цій гри ви заробляєте бали виконанням завдань. Що менше ви допускаєте помилок, то більше дістаєте балів.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har. `,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
			pl: `W miarę zdobywania punktów otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.`,
			de: `Neben Punkten erhalten Sie auch Sterne. Oben auf Ihrem Bildschirm sehen Sie, wie viele Punkte und Sterne Sie haben.`,
			ua: `Набираючи бали, ви також будете отримувати зірки. На верхній частині екрану вказано кількість зібраних балів і зірок.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-stars-to-progress',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need to get three stars to advance to the next module.`,
			pl: `Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.`,
			de: `Sie benötigen drei Sterne, um in das nächste Modul zu gelangen.`,
			ua: `Для переходу на наступний модуль потрібно зібрати три зірки.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Vi starter med nogle grundlæggende spørgsmål om fødevaresikkerhed. `,
			en: `We'll start with some questions about basic food safety.`,
			pl: `Zaczniemy od pytań na temat podstaw bezpieczeństwa żywności.`,
			de: `Wir beginnen mit ein paar Fragen zur grundlegenden Nahrungsmittelsicherheit.`,
			ua: `Для початку — питання про основи харчової безпеки.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-certified',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `På Harboe er vi certificeret efter forskellige standarder, for eksempel halal og IFS. Hvad betyder det?`,
			en: `At Harboe we are certified for different standards such as halal and IFS. <br />What does that mean?`,
			pl: `W Harboe przestrzegamy różnych standardów, w tym dotyczących żywności halal oraz IFS. Co to oznacza?`,
			de: `Bei Harboe sind wir für verschiedene Standards wie Halal und IFS zertifiziert. Was bedeutet das?`,
			ua: `У Harboe діють різні стандарти сертифікації, такі як «халяль» й IFS. Що це значить?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `At vores produkter skal leve op til bestemte krav`,
					en: `That our products have to meet certain demands`,
					pl: `Że nasz produkty muszą spełniać określone wymagania`,
					de: `Dass unsere Produkte bestimmten Anforderungen entsprechen müssen`,
					ua: `Наша продукція має відповідати певним вимогам`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `At vi sælger til hoffet`,
					en: `That we are purveyors to the royal Danish court`,
					pl: `Że jesteśmy dostawcami duńskiego dworu królewskiego.`,
					de: `Dass wir Lieferanten des königlichen dänischen Hofes sind`,
					ua: `Ми є постачальниками королівського двору Данії`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager pose-1 coat-1',
							text: {
								da: `Nej. Harboe er kongelig hofleverandør, men det er ikke et certifikat.`,
								en: `No, Harboe is supplying under Royal Warrant, but it is not a certificate.`,
								pl: `Nie, Harboe rzeczywiście posiada Patent Dostawcy Rodziny Królewskiej, ale nie jest to świadectwo.`,
								de: `Nein, Harboe liefert unter der königlichen Urkunde für Hoflieferanten, aber das ist kein Zertifikat.`,
								ua: `Ні. Хоч Harboe справді є постачальником за королівським ордером, але це не сертифікат.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-why-hygiene',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejnen, når vi producerer fødevarer?
				<br /><br />Vælg 1 - og husk at læse alle svarmulighederne:`,
			en: `Why must we be extra aware of hygiene when producing foodstuffs?
				<br /><br />Choose 1 - and remember to read all the options:`,
			pl: `Dlaczego higiena jest szczególnie istotna przy produkcji produktów spożywczych?
				<br /><br />Wybierz 1 odpowiedź i pamiętaj, aby przeczytać wszystkie opcje:`,
			de: `Warum müssen wir bei der Herstellung von Lebensmitteln besonders auf die Hygiene achten?
				<br /><br />Wählen Sie 1 - und denken Sie daran, sich alle Optionen durchzulesen:`,
			ua: `Чому ми маємо бути особливо уважними під час виробництва продуктів?
				<br /><br />Уважно прочитавши всі варіанти, виберіть один:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Because of legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					de: `Wegen gesetzlicher Bestimmungen`,
					ua: `Для відповідності вимогам закону`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because bad hygiene can make people sick`,
					pl: `Ponieważ zła higiena może prowadzić do zachorowań.`,
					de: `Weil mangelnde Hygiene Menschen krank machen kann`,
					ua: `Погана гігієна можна спричиняти хвороби в людей`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					de: `Um eine hohe Qualität zu gewährleisten`,
					ua: `Для забезпечення високої якості`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fordi kunderne forventer det`,
					en: `Because the customers expect it`,
					pl: `Ponieważ klienci oczekują tego`,
					de: `Weil es die Kunden erwarten`,
					ua: `Для відповідності очікуванням споживачів`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the above reasons`,
					pl: `Ze wszystkich powyższych przyczyn`,
					de: `Aus allen oben genannten Gründen`,
					ua: `Все з наведеного`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-clothing',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Vi kommer til at gå mere i detaljer, men nu skal det handle om påklædning.`,
			en: `We will be more specific later, but right now we'll look at how to dress properly.`,
			pl: `Później wejdziemy w szczegóły, ale na razie przyjrzymy się zasadom dotyczącym odpowiedniego ubioru.`,
			de: `Wir werden später noch genauer darauf eingehen, aber jetzt werfen wir einen Blick darauf, wie man sich richtig kleidet.`,
			ua: `Пізніше розглянемо це докладніше, а зараз — правила дрес-коду.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-jewelry',
		layout: 'harboe-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What jewelry is NOT allowed in production areas?`,
			pl: `Jakie rodzaje biżuterii NIE są dozwolone na obszarze produkcji?`,
			de: `Welcher Schmuck ist in den Produktionsbereichen NICHT erlaubt?`,
			ua: `Які прикраси ЗАБОРОНЕНІ у зонах виробництва?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // ring
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // nose piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // belly piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // watch
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // necklace
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true// tounge piercing
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-clothing-redzone',
		layout: 'harboe-m1-clothing-redzone',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Når du er i en RØD zone skal du desuden bære hårnet og eventuelt skægbind. Hvordan skal de sidde? Tryk på de rigtige billeder.`,
			en: `When in a RED zone, you also have to wear a hairnet and possibly a beard snood. How do you wear those? Click on the correct images.`,
			pl: `Przebywając w CZERWONEJ strefie, musisz nosić również siatkę na włosy i ewentualnie osłonę na brodę. Jak należy je nosić? Kliknij właściwe obrazy.`,
			de: `Wenn Sie sich in einer ROTEN Zone befinden, müssen Sie auch ein Haar- und ggf. Bartnetz tragen. Wie tragen Sie diese? Klicken Sie auf die korrekten Bilder.`,
			ua: `Перебуваючи в ЧЕРВОНІЙ зоні, необхідно носити сіточку для волосся, а за потреби також пов'язку для бороди. Як правильно їх носити? Клацніть по правильних зображеннях.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair covered, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // hair and beard covered
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager pose-1 coat-1',
							text: {
								da: `Hvis dit skæg er mere end én dag gammelt, så skal du bruge skægbind.`,
								en: `If your beard is more than a day old, you must wear a beard snood.`,
								pl: `Jeśli masz dłuższy zarost niż jednodniowy, musisz nosić osłonę na brodę.`,
								de: `Wenn Ihr Bart älter als ein Tag ist, müssen Sie ein Bartnetz tragen.`,
								ua: `Якщо ви не голили бороду понад одну добу, треба носити спеціальну пов'язку.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // hair out
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // hair covered, beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // hair covered not ears
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-long-hair-yellowzone',
		layout: 'harboe-m1-long-hair-yellowzone',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Hvis du har hår længere end til skuldrene, så gælder der også regler i GUL zone. Hvordan skal håret så sidde? Klik på det rigtige billede.`,
			en: `If your hair is longer that shoulder length, certain rules will also apply in YELLOW zones. In that case, how should you wear your hair? Click on the correct image.`,
			pl: `Jeśli Twoje włosy sięgają niżej niż ramion, niektóre zasady obowiązują również w ŻÓŁTYCH strefach. W tym przypadku jak należy nosić włosy? Kliknij właściwy obraz.`,
			de: `Wenn Ihr Haar länger als Schulterlänge ist, gelten bestimmte Regeln auch in den GELBEN Zonen. Wie sollten Sie in solch einem Fall Ihr Haar tragen? Klicken Sie auf das korrekte Bild.`,
			ua: `Якщо ваше волосся сягає нижче плечей, деякі правила починають діяти і в ЖОВТИХ зонах. Що треба робити з волоссям в такому разі? Клацніть по правильному зображенню.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // hair bun
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // hair bun with decoration
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // hair bun covered
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // braid
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-hygiene-importance-redzone',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Røde og gule zoner er klart markeret. Men hvorfor er det ekstra vigtigt med hygiejnen i rød zone?`,
			en: `Red and yellow zones are clearly marked. But why is hygiene extra important in red zones?`,
			pl: `Czerwone i żółte strefy są jasno oznaczone. Ale dlaczego higiena jest szczególnie ważna w czerwonych strefach?`,
			de: `Rote und gelbe Zonen sind deutlich markiert. Aber warum ist Hygiene in roten Zonen besonders wichtig?`,
			ua: `Червоні й жовті зони чітко позначені. Але чому в червоних зонах гігієна особливо важлива?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Fordi der er mulighed for direkte produktkontakt`,
					en: `Because we often have visitors there`,
					pl: `Ponieważ możesz wejść w bezpośredni kontakt z produktami`,
					de: `Weil man direkt mit den Produkten in Kontakt kommt`,
					ua: `Через можливість безпосереднього контакту з продукцією`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi der tit er gæster`,
					en: `Becuase we often have visitors there`,
					pl: `Ponieważ często mamy tam gości`,
					de: `Weil wir dort oft Besucher haben`,
					ua: `Через частих відвідувачів у цій зоні`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager pose-1 coat-1',
							text: {
								da: `Nej! Faktisk må der såvidt muligt slet ikke være gæster i røde zoner.`,
								en: `No! Actually no guests should be in red zones at all if possible. `,
								pl: `Nie! W miarę możliwości w czerwonej strefie nie powinno być żadnych gości.`,
								de: `Nein! In den roten Zonen sollten sich möglichst gar keine Gäste aufhalten.`,
								ua: `Ні! У червоних зонах не допускаються екскурсії й відвідувачі.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Fordi der er mulighed for at produkter kan blive forurenet`,
					en: `Because there is a risk of product getting contaminated`,
					pl: `Ponieważ istnieje ryzyko skażenia produktu`,
					de: `Weil das Risiko besteht, dass das Produkt kontaminiert werden könnte`,
					ua: `Через можливість забруднення продукції`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-too-dressingroom',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Lad os gå ind i omklædningen.`,
			en: `Let's enter the locker room.`,
			pl: `Wejdźmy do przebieralni.`,
			de: `Gehen wir nun in die Umkleidekabine.`,
			ua: `Ходімо до роздягальні.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'harboe-m1-spot-errors-dressingroom',
		layout: 'harboe-m1-dressingroom',
		background: 'dressing-room',
		text: {
			da: `Kig på billedet og se om du kan spotte de 3 fejl. Tryk OK, når du har dem, eller hvis du ikke kan finde dem alle. `,
			en: `Look at the image and see if you can spot the 3 issues. Click OK when you have them, or if you can't find them all. `,
			pl: `Spójrz na obraz i spróbuj znaleźć 3 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			de: `Schauen Sie sich das Bild an und versuchen Sie, die 3 Probleme zu erkennen. Klicken Sie auf OK wenn Sie fertig sind, oder nicht alle finden konnten.`,
			ua: `Подивіться на зображення й спробуйте знайти 3 порушення. Коли знайдете всі або скільки зможете, натисніть «ОК».`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'coffee-cup',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Glas og porcelæn må ikke fjernes fra kantinen.`,
								en: `Yes! Never bring glass or china outside the cafeteria. `,
								pl: `Tak! Nigdy nie należy wynosić szkła ani porcelany poza stołówkę.`,
								de: `Ja! Glas und Porzellan darf die Cafeteria unter keinen Umständen verlassen.`,
								ua: `Так! Не можна виносити склянки чи посуд з кафетерію.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cigaret',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Hos Harboe er der totalt forbud mod rygning bortset fra på rygepladsen ved Ladebovej.`,
								en: `Exactly. Smoking is forbidden at Harboe, except in the smoking lot near Ladebovej.`,
								pl: `Dokładnie. Palenie jest zabronione w Harboe, z wyjątkiem palarni w pobliżu Ladebovej.`,
								de: `Ganz genau. Rauchen ist bei Harboe verboten, außer im Raucherbereich an der Ladebovej.`,
								ua: `Саме так. У Harboe палити заборонено скрізь, окрім спеціально призначеної зони біля Ладебовей.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'muesli-bar',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt fanget. Nødder er ikke tilladt på Harboe.`,
								en: `Nice catch. Nuts are not allowed at Harboe.`,
								pl: `Zgadza się. Orzechy nie są dozwolone na terenie firmy Harboe.`,
								de: `Gute gesehen. Nüsse sind bei Harboe nicht erlaubt.`,
								ua: `Правильно. У Harboe заборонено приносити горіхи.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-seperate-clothing',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `På Harboe skal arbejdstøj og privat tøj holdes adskilt. `,
			en: `At Harboe you must keep work clothes and private clothes separated.`,
			pl: `W Harboe pracownicy mają obowiązek przechowywać odzież roboczą i osobistą oddzielnie.`,
			de: `Bei Harboe müssen private und Arbeitskleidung getrennt werden.`,
			ua: `У Harboe обов'язково тримати робочий і особистий одяг окремо.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'harboe-m1-spot-errors-clothing',
		layout: 'harboe-m1-clothing',
		background: 'dressing-room',
		text: {
			da: `Find de 3 fejl på billedet. Klik OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Spot the 3 issues. Click OK when you are ready, or if you can't find them all.`,
			pl: `Znajdź 3 problemy. Kliknij OK, gdy to zrobisz lub jeśli nie możesz znaleźć wszystkich problemów.`,
			de: `Finden Sie die 3 Probleme. Klicken Sie auf OK wenn Sie fertig sind, oder nicht alle finden konnten.`,
			ua: `Спробуйте знайти 3 порушення. Коли знайдете всі або скільки зможете, натисніть «ОК».`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'shoes',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, de skal nemlig stilles op - for eksempel på hylden under bænken.`,
								en: `Yes, remove those from the floor - for instance by placing them on the shelf underneath the bench.`,
								pl: `Tak, należy usunąć te rzeczy z podłogi, na przykład umieszczając je na półce pod ławką.`,
								de: `Ja, entfernen Sie diese vom Boden - und platzieren Sie sie z.B. im Regal unter der Bank.`,
								ua: `Так, їх треба прибрати з підлоги — наприклад, поставивши на полицю під лавкою.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'skirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set! Det skal bare smides ud efter brug.`,
								en: `Yes! You should just toss that after use.`,
								pl: `Tak! Należy wyrzucić to po użyciu.`,
								de: `Ja! Sie sollten es nach der Verwendung entsorgen.`,
								ua: `Так! Використавши, це треба одразу викинути.`
							}
						})
					})
				]
		}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		text: {
			da: `Håndhygiejne er meget vigtigt. Efter hvilke situationer skal du huske at vaske hænder?`,
			en: `Hand hygiene is very important. After which situations must you remember to wash your hands?`,
			pl: `Higiena rąk jest bardzo ważna. Po jakich sytuacjach należy pamiętać o umyciu rąk?`,
			de: `Handhygiene ist äußerst wichtig. Nach welchen Situationen müssen Sie Ihre Hände waschen?`,
			ua: `Гігієна рук дуже важлива. Після яких ситуацій обов'язково треба мити руки?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Før jeg starter på arbejde`,
					en: `Before starting work`,
					pl: `Przed rozpoczęciem pracy`,
					de: `Vor Arbeitsbeginn`,
					ua: `Перед початком роботи`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Hvis mine hænder er beskidte`,
					en: `If my hands are dirty`,
					pl: `Jeśli moje ręce są brudne`,
					de: `Wenn meine Hände schmutzig sind`,
					ua: `Якщо руки брудні`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Efter toiletbesøg`,
					en: `After being to the toilet`,
					pl: `Po korzystaniu z toalety`,
					de: `Nach dem Toilettengang`,
					ua: `Після походу в туалет`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Efter at have indtaget mad, drikke eller medicin`,
					en: `After drinking, eating, or taking medicine`,
					pl: `Po piciu, jedzeniu lub przyjmowaniu leków`,
					de: `Nach dem Essen, Trinken oder der Einnahme von Medikamenten`,
					ua: `Після вживання їжі, пиття й ліків`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Efter kontakt med snavset udstyr, værktøj og så videre`,
					en: `After being in contact with dirty equipment, tools, and so on`,
					pl: `Po kontakcie z brudnymi urządzeniami, narzędziami itp.`,
					de: `Nachdem man mit verschmutzer Ausrüstung, Werkzeugen usw. in Kontakt gekommen ist`,
					ua: `Після торкання брудного обладнання, інструментів тощо`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Efter rygning eller brug af lommetørklæde`,
					en: `After smoking or using a handkerchief`,
					pl: `Po paleniu lub używaniu chusteczki`,
					de: `Nach dem Rauchen oder der Verwendung eines Taschentuchs`,
					ua: `Після паління чи використання носовичка`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all of the above situations`,
					pl: `We wszystkich powyższych sytuacjach`,
					de: `In allen oben genannten Situationen`,
					ua: `Все з наведеного`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'harboe-m1-order-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Når du vasker hænder, hvad er så den rigtige rækkefølge at gøre tingene i? Sæt handlingerne i rækkefølge, og tryk så OK. `,
			en: `When washing your hands, what is the correct order of operations? Place the actions in the right order and then click OK.`,
			pl: `W jakiej kolejności należy myć ręce? Umieść działania we właściwej kolejności i kliknij OK.`,
			de: `Wie ist die korrekte Vorgehensweise, wenn Sie sich die Hände waschen? Ordnen Sie die Handlungen der Reihe nach an und klicken dann auf OK.`,
			ua: `Яка слушна послідовність дій для миття рук? Розташуйте дії в правильному порядку, а тоді натисніть «ОК».`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`,
					pl: `Umyć ręce mydłem i wodą`,
					de: `Hände mit Wasser und Seife waschen`,
					ua: `Помити руки милом і водою`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder med papir`,
					en: `Dry hands with paper towel`,
					pl: `Wysuszyć ręce papierowym ręcznikiem`,
					de: `Hände mit einem Papierhandtuch trocknen`,
					ua: `Насухо витерти руки паперовим рушником`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid papir i skraldespand`,
					en: `Put paper towel in the bin`,
					pl: `Umieścić papierowy ręcznik w koszu na śmieci`,
					de: `Papierhandtuch im Mülleimer entsorgen`,
					ua: `Викинути паперовий рушник до смітника`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug håndsprit`,
					en: `Use sanitizer`,
					pl: `Użyć środka odkażającego`,
					de: `Desinfektionsmittel verwenden`,
					ua: `Використати дезінфекційний засіб`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-washing-hands-areas',
		layout: 'harboe-m1-hands',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Der er især fire områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Four areas are especially easy to forget when washing hands. Which are they?`,
			pl: `Podczas mycia rąk szczególnie łatwo zapomnieć o czterech częściach. Jakich?`,
			de: `Beim Händewaschen gibt es vier Bereiche, die leicht vergessen werden können. Welche sind es?`,
			ua: `Миючи руки, особливо часто забувають про такі чотири місця. Які саме?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // between fingers
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true // thumb
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // pinkey
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-nails',
		layout: 'harboe-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Håndhygiejne handler også om neglene. Tryk på det billede, som er korrekt. `,
			en: `Hand hygiene is also about the nails. Click on the correct image. `,
			pl: `Higiena rąk obejmuje również paznokcie. Kliknij właściwy obraz.`,
			de: `Bei der Handhygiene geht es auch um die Fingernägel. Klicken Sie auf das richtige Bild.`,
			ua: `Гігієна рук стосується й нігтів. Клацніть по правильному зображенню.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // short and clean
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // short nailpolish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // short and dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // long and dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // fake
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-wound',
		layout: 'harboe-m1-wound',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvis du har sår eller rifter på hænderne, hvad skal du så bruge?`,
			en: `If you have wounds or scratches on your hands, what must you use?`,
			pl: `Jeśli masz rany lub zadrapania na dłoniach, czego musisz użyć?`,
			de: `Was müssen Sie verwenden, wenn Sie Wunden oder Kratzer an Ihren Händen haben?`,
			ua: `Чим слід скористатися, якщо на руках рани чи подряпини?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // band-aid
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // plastic bag
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true, // blue band-aid
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rigtigt. Og hvis du er i rød zone, så skal du også have en blå nitrilhandske ud over.`,
								en: `That's right! And if you are in a red zone, you must also wear a blue nitril glove.`,
								pl: `Tak! I jeśli jesteś w czerwonej strefie, musisz również nosić niebieskie rękawiczki nitrylowe.`,
								de: `Das ist richtig! Und wenn Sie in einer roten Zone sind, müssen Sie auch einen blauen Nitrilhandschuh tragen.`,
								ua: `Саме так! А якщо ви в червоній зоні, обов'язково також носити сині нітрилові рукавички.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // gips
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-almost-done',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Vi er næsten klar til at gå ind i produktionen. Kun to spørgsmål tilbage.`,
			en: `We are almost ready to enter production. Only two questions remaining.`,
			pl: `Jesteśmy prawie gotowi do wejścia do obszaru produkcji. Zostały już tylko dwa pytania.`,
			de: `Jetzt sind wir fast so weit, die Produktion zu betreten. Es sind nur noch zwei Fragen übrig.`,
			ua: `Ми майже готові перейти до виробництва. Лишилося тільки два запитання.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'harboe-m1-mc-footwear',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Hvilket fodtøj skal du altid have på i proces- og produktionsområdet samt på lager?`,
			en: `What shoes must you always wear in processing and production areas and in warehouses?`,
			pl: `Jakie obuwie musisz zawsze nosić w obszarach obróbki i produkcji oraz w magazynach? `,
			de: `Welche Schuhe müssen Sie stets tragen, wenn Sie sich in verarbeitenden und produzierenden Bereichen und im Lager befinden?`,
			ua: `Яке взуття обов'язково носити в зонах переробки й виробництва, а також в складських приміщеннях?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Kondisko`,
					en: `Sneakers`,
					pl: `Trampki`,
					de: `Sneakers`,
					ua: `Кросівки`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Sikkerhedssko`,
					en: `Safety shoes`,
					pl: `Obuwie ochronne`,
					de: `Sicherheitsschuhe`,
					ua: `Спецвзуття`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Træsko`,
					en: `Clogs`,
					pl: `Saboty`,
					de: `Clogs`,
					ua: `Крокси`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Gummistøvler`,
					en: `Rubberboots`,
					pl: `Gumiaki`,
					de: `Gummistiefel`,
					ua: `Ґумові чоботи`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Det vælger jeg selv`,
					en: `It's up to me`,
					pl: `To zależy ode mnie`,
					de: `Das bleibt mir überlassen`,
					ua: `На власний розсуд`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 'harboe-m1-survey-disease-question',
		shuffleOptions: false,
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		title: {
			da: `Informer om sygdom`,
			en: `Inform about illness`,
			pl: ``,
			de: ``
		},
		text: {
			da: `Er du bekendt med følgende? 
				<br /><br />Hvis du har fået norovirus (roskildesyge), skal du være sygemeldt indtil 48 timer efter symptomers ophør.`,
			en: `Are you aware of the following:
				<br /><br />If you have norovirus (stomach flu), you must be on sick leave until 48 hours after symptoms are gone.`,
			pl: `Czy wiesz, że: 
				<br /><br />Jeśli masz norowirusa (grypę żołądkową), musisz być na zwolnieniu lekarskim do 48 godzin po ustaniu objawów.`,
			de: `Sind Sie sich folgendem Bewusst?
				<br /><br />Wenn Sie einen Norovirus (Magen-Darm-Grippe) haben, müssen Sie sich bis 48 Stunden nach Abklingen der Symptome krank melden.`,
			ua: `Чи відомо вам наступне? 
				<br /><br />Якщо у вас норовірус (кишковий грип), ви зобов'язані взяти лікарняний на весь період хвороби, плюс 48 годин після зникнення симптомів.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, det er jeg klar over`,
					en: `Yes, I am aware of that`,
					pl: `Tak, wiem o tym`,
					de: `Ja, ich bin mir dessen bewusst`,
					ua: `Так, мені це відомо`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Hvis vi ved den slags, så kan vi bedre sikre en høj hygiejnestandard.`,
								en: `Good! If we know these things, we can ensure a high hygiene standard.`,
								pl: `Dobrze! Wiedząc te rzeczy, możemy przestrzegać wysokich standardów dotyczących higieny.`,
								de: `Gut! Wenn wir diese Dinge wissen, können wir einen hohen Hygienestandard gewährleisten.`,
								ua: `Добре! Знаючи ці речі, ми можемо забезпечити дотримання високих стандартів гігієни.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det vidste jeg ikke`,
					en: `No, I did not know that`,
					pl: `Nie, nie wiedziałem(-am) tego`,
					de: `Nein, das wusste ich nicht`,
					ua: `Ні, вперше чую`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt du siger det. Når du er færdig med modulet her, så tal straks med en leder, så du kan få styr på det.`,
								en: `It's good that you tell us. When you are done with this training, talk to your manager to make sure you know the rules.`,
								pl: `Dobrze, że nam o tym mówisz. Gdy ukończysz szkolenie, porozmawiaj z przełożonym, aby poznać obowiązujące zasady.`,
								de: `Gut, dass Sie uns das sagen. Sprechen Sie mit Ihrem Manager, wenn Sie mit diesem Training fertig sind, um sicherzustellen, dass Sie mit den Regeln vertraut sind.`,
								ua: `Добре, що ви з нами відверті. Після завершення тренування, зверніться до свого менеджера й переконайтеся, що ви знаєте правила.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'harboe-m1-instructions-finished',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1 coat-1'],
		text: {
			da: `Du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til.`,
			en: `That's it, you have completed the first module. If you have three stars, you can continue to the next module. If not, you have to take this one again.`,
			pl: `To koniec pierwszego modułu. Jeśli masz trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz przejść ten moduł ponownie.`,
			de: `Das war alles, Sie haben das erste Modul abgeschlossen. Wenn Sie drei Sterne haben, können Sie mit dem nächsten Modul fortfahren. Wenn nicht, müssen Sie dieses Modul wiederholen.`,
			ua: `Це все — ви пройшли перший модуль. Якщо зібрано три зірки, можете перейти до наступного. В іншому разі доведеться пройти його заново.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};