// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Początek',
		de: 'Start',
		ua: 'старт'
	},
	login: {
		da: 'Log ind',
		en: 'Log in',
		pl: 'Zaloguj się',
		de: 'Anmeldung',
		ua: 'авторизуватися'
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
		pl: 'Wyloguj się',
		de: 'Ausloggen',
		ua: 'вийти'
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		pl: 'Gracz',
		de: 'Spieler',
		ua: 'гравець'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		pl: 'Moderator',
		de: 'Moderator',
		ua: 'Фасилітатор'
	},
	admin: {
		da: 'Administrator',
		en: 'Administrator',
		pl: 'Administrator',
		de: 'Administrator',
		ua: 'Адміністратор'
	},
	email: {
		da: 'e-mail',
		en: 'e-mail',
		pl: 'e-mail',
		de: 'e-mail',
		ua: 'електронна пошта'
	},
	password: {
		da: 'password',
		en: 'password',
		pl: 'hasło',
		de: 'passwort',
		ua: 'пароль'
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password',
		pl: 'powtórz hasło',
		de: 'passwort wiederholen',
		ua: 'повторити пароль'
	},
	userId: {
		da: 'medarbejder-nr.',
		en: 'employee no.',
		pl: 'numer pracownika',
		de: 'mitarbeiter-Nr.',
		ua: 'номер працівника'
	},
	name: {
		da: 'navn',
		en: 'name',
		pl: 'nazwa',
		de: 'name',
		ua: 'назва'
	},
	loginWithUserId: {
		da: 'Log ind med medarbejder-nr.',
		en: 'Log in with employee no.',
		pl: 'Zaloguj się za pomocą numeru pracownika',
		de: 'Melden Sie sich mit der Mitarbeiter-Nr. an.',
		ua: 'Увійдіть під номером працівника.'
	},
	createNewUser: {
		da: 'Opret ny bruger',
		en: 'Create new user',
		pl: 'Utwórz nowego użytkownika',
		de: 'Neuen Benutzer erstellen',
		ua: 'Створіть нового користувача'
	},
	forgotPassword: {
		da: 'Glemt dit password?',
		en: 'Forgot your password?',
		pl: 'Zapomniałeś hasła?',
		de: 'Haben Sie Ihr Passwort vergessen?',
		ua: 'Забули свій пароль?'
	},
	resetPassword: {
		da: 'Nulstil password',
		en: 'Reset tpassword',
		pl: 'Zresetuj hasło',
		de: 'Passwort zurücksetzen',
		ua: 'Скинути пароль'
	},
	goToLoginBtn: {
		da: 'Tilbage til login',
		en: 'Back to login',
		pl: 'Powrót do logowania',
		de: 'Zurück zur Anmeldung',
		ua: 'Назад до входу'
	},
	createUserBtn: {
		da: 'Opret',
		en: 'Create',
		pl: 'Utwórz',
		de: 'Erstellen',
		ua: 'Створити'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen',
		ua: 'скинути'
	},
	cancel: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		ua: 'Відключити'
	},
	cancel2: {
		da: 'Annuller',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		ua: 'Скасувати'
	},
	createUserFeedback: {
		da: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
		en: 'Your new user has been created. Go back to log in to play.',
		pl: 'Twój użytkownik został utworzony. Wróć i zaloguj się, aby grać.',
		de: 'Ihr neuer Benutzer wurde erstellt. Gehen Sie zurück, um sich zum Spielen anzumelden.',
		ua: 'Ваш користувач створений. Поверніться та увійдіть, щоб грати.'
	},
	passwordResetFeedback: {
		da: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
		en: 'You have received a link to reset your password in your inbox.',
		pl: 'Otrzymałeś link do zresetowania hasła w swojej skrzynce odbiorczej.',
		de: 'Sie haben in Ihrem Posteingang einen Link zum Zurücksetzen Ihres Passworts erhalten.',
		ua: 'Ви отримали посилання для скидання пароля на свою поштову скриньку.'
	}
};




module.exports = {
	loginUiTexts
};