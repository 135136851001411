// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Login */
	workerId: {
		da: 'Er dette dit medarbejdernummer?',
		en: 'Is this your employee number?',
		pl: 'Czy to Twów numer pracownika?',
		de: 'Ist das Ihre Mitarbeiternummer?',
		ua: 'Це номер вашого працівника?'
	},
	yes: {
		da: 'Ja',
		en: 'Yes',
		pl: 'Tak',
		de: 'Ja',
		ua: 'Так'
	},
	no: {
		da: 'Nej',
		en: 'No',
		pl: 'Nie',
		de: 'Nein',
		ua: 'Немає'
	},

	/* Lobby */
	noGame: {
		da: 'Der findes ikke noget spil under denne URL',
		en: 'There is no game connected to this URL',
		pl: 'Z tym adresem URL nie jest powiązana żadna gra',
		de: 'Mit dieser URL ist kein Spiel verbunden',
		ua: 'За цією URL-адресою немає гри'
	},
	playerDiffGameConfirmId: {
		da: `Det ser ud til dit medarbejdernummer %userId% hører til på %gameName%. 
			<br /><br />Er du sikker på, det er dit medarbejdernummer?`,
		en: `It looks like you employee id %userId% is connected to %gameName%.
			<br /><br />Are you sure it is your employee id?`,
		pl: `Wygląda na to, że Twój numer pracownika %userId% należy do %gameName%. 
			<br /><br />Czy na pewno jest to Twój numer pracownika?`,
		de: `Offenbar ist Ihre Mitarbeiter-ID %userId% mit %gameName% verbunden.
			<br /><br />Sind Sie sicher, dass es sich um Ihre Mitarbeiter-ID handelt?`,
		ua: `Схоже, ваш номер працівника %userId% належить %gameName%.
			<br /><br />Ви впевнені, що це номер вашого працівника?`
	},	
	noLogOut : {
		da: 'Nej, log ud',
		en: 'No, log out',
		pl: 'Nie, wyloguj się',
		de: 'Nein, melden Sie sich ab',
		ua: 'Ні, вийти'
	},
	playerDiffGame: {
		da: `Du er allerede i gang med %gameName%.
			<br/><br/>Du kan kun være i gang med ét spil ad gangen.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
		pl: `Jesteś obecnie połączony z %gameName%. 
			<br /><br />W danym momencie możesz być połączony tylko z jedną grą.`,
		de: `Sie sind derzeit mit %gameName% verbunden.
			<br /><br />Sie können jeweils nur mit einem Spiel verbunden sein.`,
		ua: `Ви вже граєте в %gameName%.
			<br /><br />Ви можете бути активними лише в одній грі одночасно.`
	},
	gameDeleted: {
		da: '??? (slettet)',
		en: '??? (deleted)',
		pl: '??? (usunięte)',
		de: '??? (gelöscht)',
		ua: '??? (видалено)'
	},
	goToGameBtn: {
		da: 'Gå til %gameName%',
		en: 'Go to %gameName%',
		pl: 'Przejdź do %gameName%',
		de: 'Gehe zu %gameName%',
		ua: 'Перейти до %gameName%'
	},
	switchGameBtn: {
		da: 'Skift til %gameName%',
		en: 'Switch to %gameName%',
		pl: 'Przełącz na %gameName%',
		de: 'Wechseln Sie zu %gameName%',
		ua: 'Перейти на %gameName%'
	},
	switchGameWarning: {
		da: `Du kan kun være i gang med ét spil ad gangen.
			<br /><br/>Hvis du skifter til %gameName2% vil al din data for %gameName1% blive slettet.
			<br /><br/>Er du sikker på, du vil skifte til %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
		pl: `Możesz być połączony tylko z jedną grą naraz.
			<br /><br />Jeśli przełączysz się na %gameName2%, wszystkie Twoje postępy w %gameName1% zostaną utracone.
			<br /><br />Czy na pewno chcesz przełączyć się na %gameName2%?`,
		de: `Sie können jeweils nur mit einem Spiel verbunden sein.
			<br /><br />Wenn Sie zu %gameName2% wechseln, gehen alle Ihre Fortschritte in %gameName1% verloren.
			<br /><br />Sind Sie sicher, dass Sie zu %gameName2% wechseln möchten?e you want to switch to %gameName2%?`,
		ua: `Ви можете бути активними лише в одній грі одночасно.
			<br /><br/>Якщо ви перейдете на %gameName2%, усі ваші дані для %gameName1% буде видалено.
			<br /><br/>Ви впевнені, що бажаєте перейти на %gameName2%?`
	},
	cancelBtn: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren',
		ua: 'Відключити'
	},
	confirmBtn: {
		da: 'Ja, skift spil',
		en: 'Yes, switch game',
		pl: 'Tak, zmień grę',
		de: 'Ja, Spiel wechseln',
		ua: 'Так, змінити гру'
	},

	/* Choose language */
	chooseLanguage: {
		da: 'Vælg sprog',
		en: 'Choose language',
		pl: 'Wybierz język',
		de: 'Sprache wählen',
		ua: 'Оберіть мову'
	},

	/* Player info */
	playerInfo: {
		da: 'Indtast dit navn og e-mail for at spille.',
		en: 'Enter your name and e-mail to player.',
		de: 'Geben Sie Ihren Namen und Ihre E-Mail-Adresse an den Spieler ein.',
		pl: 'Wpisz swoje imię i adres e-mail do gracza.',
		ua: `Введіть своє ім'я та електронну адресу, щоб грати.`
	},
	playerInfoName: {
		da: 'Navn',
		en: 'Name',
		de: 'Name',
		pl: 'Nazwa',
		ua: `Ім'я`
	},
	playerInfoEmail: {
		da: 'E-mail',
		en: 'E-mail',
		de: 'E-mail',
		pl: 'E-mail',
		ua: `Електронна пошта`
	},


	/* Module overview */
	reset: {
		da: 'Reset',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen',
		ua: `Скинути`
	},

	moduleOverview: {
		da: 'Moduloversigt',
		en: 'Module overview',
		pl: 'Przegląd modułów',
		de: 'Modul Übersicht',
		ua: `Огляд модуля`
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
		pl: 'Numer pracownika',
		de: 'Mitarbeiter-Nr.',
		ua: 'Співробітник №'
	},

	/* Module - general */
	continue: {
		da: 'Videre',
		en: 'Next',
		de: 'Nächste',
		pl: 'Następny',
		ua: 'Продовжити'
	},
	ok: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		de: 'OK',
		ua: 'в порядку'
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		pl: 'Wybierz',
		de: 'Wähle',
		ua: 'вибрати'
	},
	option: {
		da: 'svar',
		en: 'option',
		pl: 'opcja',
		de: 'möglichkeit',
		ua: 'відповісти'
	},
	options: {
		da: 'svar',
		en: 'options',
		pl: 'opcje',
		de: 'optionen',
		ua: 'варіанти'
	},
	finishBtn: {
		da: 'Afslut',
		en: 'Finish',
		pl: 'Zakończ',
		de: 'Beenden',
		ua: 'Вихід'
	},

	/* Module - results */
	results: {
		da: 'resultat',
		en: 'results',
		pl: 'wyniki',
		de: 'ergebnisse',
		ua: 'результат'
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
		pl: 'Gwiazdki',
		de: 'Sterne',
		ua: 'Зірки'
	},
	points: {
		da: 'Point',
		en: 'Points',
		pl: 'Punkty',
		de: 'Punkte',
		ua: 'точка'
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		pl: 'Spędzony czas',
		de: 'Zeitaufwand',
		ua: 'Витрачений час'
	},
	avgErrors: {
		da: 'Antal fejl per opgave (gennemsnit)',
		en: 'Average mistakes per task',
		pl: 'Średnia liczba błędów na zadanie',
		de: 'Durchschnittliche Fehler pro Aufgabe',
		ua: 'Кількість помилок на завдання (середнє)'
	},
	noErrorsTasks: {
		da: 'Antal opgaver løst uden fejl',
		en: 'Number of tasks solved without mistakes',
		pl: 'Liczba zadań rozwiązanych bez błędów',
		de: 'Anzahl der fehlerfrei gelösten Aufgaben',
		ua: 'Кількість завдань, виконаних без помилок'
	},
	backToModuleOverview: {
		da: 'Tilbage til moduloversigt',
		en: 'Back to module overview',
		pl: 'Powrót do przeglądu modułów',
		de: 'Zurück zur Modulübersicht',
		ua: 'Повернутися до огляду модуля'
	},
	tryAgain: {
		da: 'Prøv igen',
		en: 'Try again',
		pl: 'Spróbuj ponownie',
		de: 'Versuchen Sie es erneut',
		ua: 'Спробуйте знову'
	},
	confirmTryAgainPopup: {
		title: {
			da: 'Prøv igen',
			en: 'Try again'	,
			pl: 'Spróbuj ponownie',
			de: 'Versuche es erneut',
			ua: 'Спробуйте знову'
		},
		text: {
			da: 'Vil du prøve at slå din egen score? Du kan nulstille modulet og spille igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
			pl: 'Chcesz spróbować pobić swój własny wynik? Możesz zresetować moduł i grać ponownie.',
			de: 'Möchtest Du versuchen, deinen eigenen Punktestand zu übertreffen? Du kannst das Modul zurücksetzen und erneut spielen.',
			ua: 'Хочете спробувати побити власний рахунок? Ви можете скинути модуль і грати знову.'
		},
		resetBtn: {
			da: 'Nulstil',
			en: 'Reset',
			pl: 'Zresetuj',
			de: 'Zurücksetzen',
			ua: 'скинути'
		},
		cancelBtn: {
			da: 'Afbryd',
			en: 'Cancel',
			pl: 'Anuluj',
			de: 'Stornieren',
			ua: 'Відключити'
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		da: 'Vælg %X%',
		en: 'Choose %X%',
		pl: 'Wybierz %X%',
		de: 'Wählen Sie %X%',
		ua: 'Виберіть %X%'
	},
	surveyChooseMaxXOptions: {
		da: 'Vælg op til %X%',
		en: 'Choose up to %X%',
		pl: 'Wybierz do %X%',
		de: 'Wählen Sie bis zu %X%',
		ua: 'Виберіть до %X%'
	},

	/* Module - order task */
	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
			pl: 'Masz %correctAnswers%/%totalAnswers%.',
			de: 'Du hast %correctAnswers%/%totalAnswers% erhalten.',
			ua: 'Ви отримали %correctAnswers%/%totalAnswers%.'
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
			pl: 'Spróbuj ponownie!',
			de: 'Versuche es erneut!',
			ua: 'Спробуйте знову!'
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			da: 'Ups!',
			en: 'Oops!',
			pl: 'Ups!',
			de: 'Hoppla!',
			ua: 'Ой!'
		},
		text1: {
			da: 'Du fandt kun %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Znalazłeś tylko %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast nur %spottedErrors% von %numberOfErrors% Fehlern.',
			ua: 'Ви знайшли лише %spottedErrors% із %numberOfErrors% помилок.'
		},
		title2: {
			da: 'Ikke dårligt!',
			en: 'Not bad!',
			pl: 'Nieźle!',
			de: 'Nicht schlecht!',
			ua: 'Непогано!'
		},
		text2: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Zauważyłeś %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Du hast %spottedErrors% von %numberOfErrors% Fehlern entdeckt.',
			ua: 'Ви знайшли %spottedErrors% із %numberOfErrors% помилок.'
		},
		title3: {
			da: 'Godt klaret!',
			en: 'Well done!',
			pl: 'Dobra robota!',
			de: 'Gut gemacht!',
			ua: 'молодець!'
		},
		text3: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
			pl: 'Zauważyłeś %spottedErrors% z %numberOfErrors% błędów.',
			de: 'Sie haben %spottedErrors% von %numberOfErrors% Fehlern entdeckt.',
			ua: 'Ви знайшли %spottedErrors% із %numberOfErrors% помилок.'
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			da: 'Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
			pl: 'W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować.',
			de: 'In diesem Modul sind zu viele Fehler aufgetreten. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.',
			ua: 'Ви зробили забагато помилок у модулі. Поговоріть з керівником курсу про подальший процес.'
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok',
			ua: 'в порядку'
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
				<br /><br />Modulet er låst indtil %date%.`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
			pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować. 
				<br /><br />Ten moduł będzie zablokowany do dnia %date%`,
			de: `Du hast in diesem Modul zu viele Fehler gemacht. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.
				<br /><br />Dieses Modul wird bis zum %date% gesperrt.`,
			ua: `Ви зробили забагато помилок у модулі. Поговоріть з керівником курсу про подальший процес.
				<br /><br />Модуль заблоковано до %date%.`
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
			pl: 'Ok',
			de: 'Ok',
			ua: 'в порядку'
		}
	}
};

module.exports = {
	playerUiTexts
};