const moduleImages = [	
	'modules/tasks/multiple-choice/harboe-m1-clothing-redzone/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m1-clothing-redzone/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m1-clothing-redzone/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m1-clothing-redzone/option-4.svg',
	'modules/tasks/multiple-choice/harboe-m1-clothing-redzone/option-5.svg',
	'modules/tasks/multiple-choice/harboe-m1-hands/background.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-4.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-5.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-6.svg',
	'modules/tasks/multiple-choice/harboe-m1-jewelry/option-7.svg',
	'modules/tasks/multiple-choice/harboe-m1-long-hair-yellowzone/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m1-long-hair-yellowzone/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m1-long-hair-yellowzone/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m1-long-hair-yellowzone/option-4.svg',
	'modules/tasks/multiple-choice/harboe-m1-nails/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m1-nails/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m1-nails/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m1-nails/option-4.svg',
	'modules/tasks/multiple-choice/harboe-m1-nails/option-5.svg',
	'modules/tasks/multiple-choice/harboe-m1-wound/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m1-wound/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m1-wound/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m1-wound/option-4.svg',
	
	'modules/tasks/spot-errors/harboe-m1-clothing/background.svg',
	'modules/tasks/spot-errors/harboe-m1-dressingroom/background.svg',
];

export {
	moduleImages
};