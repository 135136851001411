const moduleImages = [
	'modules/tasks/multiple-choice/harboe-m2-clothing-redzone/option-1.svg',
	'modules/tasks/multiple-choice/harboe-m2-clothing-redzone/option-2.svg',
	'modules/tasks/multiple-choice/harboe-m2-clothing-redzone/option-3.svg',
	'modules/tasks/multiple-choice/harboe-m2-clothing-redzone/option-4.svg',
	'modules/tasks/multiple-choice/harboe-m2-clothing-redzone/option-5.svg',
	
	'modules/tasks/spot-errors/harboe-m2-production/background.svg',
	'modules/tasks/spot-errors/harboe-m2-production-hygiene-issues/background.svg',
];

export {
	moduleImages
};