const scenariosData = [
	{
		id: 'scenario-1',
		playerLoginType: 'employee-id',
		title: {
			da: 'Grundlæggende hygiejnetræning',
			en: '',
		},
		languageIds: ['da', 'en', 'de', 'pl', 'ua'],
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
];

export {
	scenariosData
};