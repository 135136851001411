const modulesData = [
	{
		id: 's1-m1',
		background: 'dressing-room',
		title: {
			da: 'Hygiejnetræning A',
			en: 'Hygiene training A',
			de: 'Hygieneschulung A',
			pl: 'Szkolenie higieniczne A',
			ua: 'Гігієнічне навчання А'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
	{
		id: 's1-m2',
		background: 'production',
		title: {
			da: 'Hygiejnetræning B',
			en: 'Hygiene training B',
			de: 'Hygieneschulung B',
			pl: 'Szkolenie higieniczne B',
			ua: 'Навчання гігієні В'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m1'],
		tasks: require('./modules/module-2').moduleTasks,
	},
	/* {
		id: 's1-m3',
		background: 'production',
		title: {
			da: 'Gentræning A',
			en: 'Repetition A',
			de: 'Wiederholung A',
			pl: 'Powtórzenie A',
			ua: 'Перепідготовка А'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m2'],
		tasks: require('./modules/module-3').moduleTasks,
	},
	{
		id: 's1-m4',
		background: 'production',
		title: {
			da: 'Gentræning B',
			en: 'Repetition B',
			de: 'Wiederholung B',
			pl: 'Powtórzenie B',
			ua: 'Перепідготовка В'
		},
		minStars: 3,
		requiredModuleIds: ['s1-m3'],
		tasks: require('./modules/module-4').moduleTasks,
	}, */
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Hygiejnetræning',
			en: 'Hygiene training',
			de: 'Hygieneschulung',
			pl: 'Szkolenie higieniczne',
			ua: 'Гігієнічне навчання'
		},
		moduleIds: [
			's1-m1',
			's1-m2'
		]
	},
	/* {
		id: 's1-mg2',
		courseNumber: 'xxxxx',
		title: {
			da: 'Gentræning',
			en: 'Repetition',
			de: 'Wiederholung',
			pl: 'Powtórzenie',
			ua: 'Перекваліфікація'
		},
		moduleIds: [
			's1-m3',
			's1-m4'
		]
	}, */
];

export {
	modulesData,
	moduleGroups
};