// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	hygieneTraining: {
		da: 'Hygiejnetræning',
		en: 'Hygiene training',
		pl: 'Szkolenie higieniczne',
		de: 'Hygieneschulung',
		ua: 'Гігієнічне навчання'
	},
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Początek',
		de: 'Start',
		ua: 'старт'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		pl: 'Ładowanie',
		de: 'Wird geladen',
		ua: 'Навантажувач'
	}
};


module.exports = {
	generalUiTexts
};